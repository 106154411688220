import React from 'react'
import { useParams, useLocation} from "react-router-dom";
import {ThemeContext} from '../context/ThemeProvider';
import { fetchConnection } from '../services/controller';

const ButtonEmbed = () => {

    const {theme, setTheme} = React.useContext(ThemeContext)

    const { robot } = useParams();

    const useQuery = () =>{
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    const getConnection = async (nameRobot) => {

        const key = query.get("key");
        const landing = query.get("landing");
        const uniqid = query.get("uniqid")

        const json = await fetchConnection(key, nameRobot, landing, uniqid);

        const showButtonsMinimizeClose = query.get("showButtonsMinimizeClose");
    
        setTheme({
            ...theme, 
            id: json.data.id,
            name: json.data.name,
            slug: nameRobot,       
            button_path: json.data.button_path,       
            key: key,
            landing: landing,
            uniqid: uniqid,
            showButtonsMinimizeClose: showButtonsMinimizeClose == null ? true : showButtonsMinimizeClose == "true" ? true : false
        });

    }

    React.useEffect(() => {
        getConnection(robot);
    },[setTheme])


    const openChat = () => {
        let url =
            window.location != window.parent.location
            ? document.referrer
            : document.location.href;

        const data = {
            'message'   : 'openChat',
            'id'        : theme.slug,
            'key'       : theme.key,
            'landing'   : theme.landing,
            'uniqid'    : theme.uniqid,
            'showButtonsMinimizeClose':theme.showButtonsMinimizeClose,
        }
        window.parent.postMessage(data, url);
    }


    if (theme.id == undefined) {
        return (
            <div className="d-flex justify-content-center" style={{height:'100vh'}}>
                <div className="spinner-border text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-center text-center" style={{height:'100vh'}}>
            <button className="btn openChat" onClick={() => openChat()}>
                <img src={theme.button_path}></img>
            </button>
        </div>
        
    )


}

export default ButtonEmbed
