import React from 'react'
import {ThemeContext} from '../context/ThemeProvider';
import minimizeButton from '../assets/minimize-button.svg'
import closeButton from '../assets/close-button.svg'
import Swal from 'sweetalert2'
import { fetchSaveAction } from "../services/controller";


const Navbar = () => {

    const {theme} = React.useContext(ThemeContext)

    const url =
            window.location != window.parent.location
            ? document.referrer
            : document.location.href;

    const closeChat = () => {

        Swal.fire({
            title: '¿Está seguro?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, lo estoy!',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                
                theme.bot.end();

                const data = {
                    message : 'closeChat',
                    id      : theme.slug,
                    key     : theme.key,
                    landing : theme.landing,
                    uniqid  : theme.uniqid,
                    showButtonsMinimizeClose: theme.showButtonsMinimizeClose
                }
                window.parent.postMessage(data, url);

            }
        })
    }

    const saveAction = async (type) => {
        await fetchSaveAction(type, theme.headId, theme.key);
    };
    

    const minimizeChat = () => {
        saveAction('Minimizar robot');

        const data = {
            message : 'minimizeChat',
            id      : theme.slug,
            key     : theme.key,
            landing : theme.landing,
            uniqid  : theme.uniqid,
            showButtonsMinimizeClose: theme.showButtonsMinimizeClose
        }
        window.parent.postMessage(data, url);
    }

    return (
        <nav className="navbar navbar-light bg-light">
            <span className="navbar-brand mb-0 h1">{ theme.show_title && theme.name }</span>

            {
                window.self !== window.top && theme.showButtonsMinimizeClose && 
                <div>
                    <button className="btn p-0" onClick={()=>minimizeChat()} >
                        <img src={closeButton} alt="Close button" width="34"/>
                    </button>
                </div>
            }

            {
                window.self === window.top &&
                    <button className="btn btn-outline-secondary my-2 my-sm-0" onClick={()=>closeChat()}>Finalizar Chat</button>
            }
                
        </nav>
    )
}

export default Navbar
