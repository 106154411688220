const baseUrl = 'https://controller.ignicia.com/api/v1.0/robots';
//const baseUrl = 'http://localhost:8002/api/v1.0/robots';


export async function fetchConnection(key, name, landing, uniqid) {

    const config = {
        method: 'GET',
        headers: { "x-controller-apikey": key }
    }

    const url = `${baseUrl}/${name}/connection?landing=${landing}&uniqid=${uniqid}`;

    const response = await fetch(url, config);

    const json = await response.json();
    return json;
}


export async function fetchGetIp() {
    const response = await fetch('https://api.ipify.org/?format=json');
    const json = await response.json();
    return json.ip;
}


export async function fetchSaveData(data, key) {
    const config = {
        method: 'POST',
        headers: { "x-controller-apikey": key, "Content-Type": "application/json" },
        body: JSON.stringify(data)
    }

    const url = `${baseUrl}/response`;
    return await fetch(url, config);
}


export async function fetchSaveAnswer(data, key) {
    const config = {
        method: 'POST',
        headers: { "x-controller-apikey": key, "Content-Type": "application/json" },
        body: JSON.stringify(data)
    }

    const url = `${baseUrl}/response-answer`;
    return await fetch(url, config);
}


export async function fetchSaveAction(type, head_id, key) {

    const data = { type: type, head_id: head_id };

    const config = {
        method: 'POST',
        headers: { "x-controller-apikey": key, "Content-Type": "application/json" },
        body: JSON.stringify(data)
    }

    const url = `${baseUrl}/action`;
    return await fetch(url, config);
}

export default {
    fetchConnection, fetchGetIp, fetchSaveData, fetchSaveAction
}