import React from "react";
import { useParams, useLocation } from "react-router-dom";
import YveBot from "yve-bot/ui";
import { ThemeContext } from "../context/ThemeProvider";
import { fetchSaveAction } from "../services/controller";

import {
  fetchConnection,
  fetchGetIp,
  fetchSaveData,
  fetchSaveAnswer,
} from "../services/controller";

const Bot = (props) => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  //const [ip, setIp] = React.useState("0.0.0.0");
  const ip = '0.0.0.0';

  const { robot } = useParams();

  // const {handleRestart} = props

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  let bot = new YveBot(props.rules || [], {
    target: ".Bot",
  });

  /*
  const getIp = async () => {
    const _ip = await fetchGetIp();
    setIp(_ip);
  };
  */


  const setIdleTimeout = (millis, onIdle, onUnidle) => {
    var timeout = 0;
    startTimer();

    function startTimer() {
        timeout = setTimeout(onExpires, millis);
        document.addEventListener("mousemove", onActivity);
        document.addEventListener("keypress", onActivity);
    }
    
    function onExpires() {
        timeout = 0;
        onIdle();
    }

    function onActivity() {
        if (timeout) clearTimeout(timeout);
        else onUnidle();
        //since the mouse is moving, we turn off our event hooks for 1 second
        document.removeEventListener("mousemove", onActivity);
        document.removeEventListener("keypress", onActivity);
        setTimeout(startTimer, 1000);
    }
  }

  React.useEffect(() => {
    bot.start();
    //getIp();

    setTheme({ ...theme, bot });

    if(!finishBot){
    
      setIdleTimeout(
        theme.timeout, 
        () => {

          if(!finishBot){
            finishBot = true;
            theme.actionClose = `Chat abandonado - ${currentMessage}`;
            bot.end();
          }

        }, 
        () => { /** Regreso al chat */}
      );
  }
    
    
  }, [setTheme]);

  let headId = theme.headId;

  const saveData = async (data, key) => await fetchSaveData(data, key);

  const saveAction = async (type, headId) => await fetchSaveAction(type, headId, theme.key);

  const saveAnswer = async (data) => await fetchSaveAnswer(data, theme.key);

  let answers = [];
  let finishBot = false;
  let currentMessage = '';

  bot.actions.define(
    "redirect_whatsapp",
    (actionOptions, rule, bot) => {
      redirect_whatsapp(actionOptions, rule, bot);
    },
    { override: true }
  );

  bot.actions.define(
    "redirect_url",
    (actionOptions, rule, bot) => {
      redirect_url(actionOptions, rule);
    },
    { override: true }
  );

  bot.on("end", function (response) {
    end(response);
  });

  bot.on("storeChanged", function (response) {
    storeChanged(response);
  });

  bot.on('talk', (message, rule, sessionId) => {
    currentMessage = message;
  });

  const restartChat = async () => {

    finishBot = false;
    
    answers = []; // Reiniciar respuestas enviadas

    // Registrar evento
    await saveAction("Reiniciar robot", headId);

    headId = await getConnection(robot);
    //setTheme({...theme, headId: headId});

    document.querySelector(".Bot").innerHTML = "";

    const bot = new YveBot(theme.rules, {
      target: ".Bot", // Etiqueta
    });

    // Actions
    bot.actions.define(
      "redirect_whatsapp",
      (actionOptions, rule, bot) => {
        redirect_whatsapp(actionOptions, rule);
      },
      { override: true }
    );

    bot.actions.define(
      "redirect_url",
      (actionOptions, rule, bot) => {
        redirect_url(actionOptions, rule);
      },
      { override: true }
    );

    bot.on("end", function (response) {
      end(response);
    });

    bot.on("storeChanged", function (response) {
      storeChanged(response);
    });

    bot.start();

    setTheme({ ...theme, bot:bot, headId: headId });
  };

  const getConnection = async (nameRobot) => {
    const json = await fetchConnection(query.get("key"), nameRobot, query.get("landing"), query.get("uniqid"));
    headId = json.data.head_id
    
    return headId;
  };

  const end = (response) => {

    finishBot = true;

    let output = [];

    for (const indexMessage in theme.messages) {
      let message = theme.messages[indexMessage];
      if (response.hasOwnProperty(message["name"])) {
        output.push({
          name: message["name"],
          message: message["message"],
          value: response[message["name"]],
          type: message["type"],
        });
      }
    }

    const request = {
      robot_response_head_id: headId,
      data: JSON.stringify(output),
      robot_id: theme.id,
      ip: ip,
      recipients: theme.recipients,
      recipients_cco: theme.recipients_cco,
      chatbot: theme.name,
    };

    saveData(request, theme.key); // Guardar todas la respuestas y acciones
    saveAction(theme.actionClose, headId); // Registrar la acción realizada

    // Agregar texto finalizó
    document
      .querySelector(".yvebot-conversation")
      .insertAdjacentHTML(
        "beforeend",
        `<li class="yvebot-thread"><div class="yvebot-message-ended">El chat finalizó</div></li>`
      );

    // Agregar botón reiniciar
    document.querySelector(".Bot form").innerHTML =
      '<button type="button" class="yvebot-button-restart">Iniciar un nuevo chat</button>';
    document
      .querySelector(".yvebot-button-restart")
      .addEventListener("click", () => restartChat());
    //document.querySelector('.yvebot-button-restart').addEventListener('click', () => handleRestart());
    
  };

  const storeChanged = (response) => {

    const { output } = response;

    if(Object.keys(output).length) {

      let data = {};

      for (const indexMessage in theme.messages) {
        let message = theme.messages[indexMessage];
        if (output.hasOwnProperty(message["name"]) && answers.indexOf(message["name"]) == -1) {
          data = {
            name: message["name"],
            message: message["message"],
            answer: output[message["name"]],
            type: message["type"],
            robot_response_head_id: headId
          };

          answers.push(message['name']);
        }
      }

      if(Object.keys(data).length) {
        data['action'] = `Mensaje: ${data['message']}`;
        saveAnswer(data, theme.key);
      }

    }
    
  };

  const redirect_url = (actionOptions, rule) => {
    const { url } = actionOptions;

    let data = {};
    for (const indexMessage in theme.messages) {
      let message = theme.messages[indexMessage];
      if (message["name"] == rule.name) {
        data = {
          name: message["name"],
          message: message["message"],
          answer: url,
          type: message["type"],
          robot_response_head_id: headId
        };
        break;
      }
    }

    if(Object.keys(data).length) {
      data['action'] = `Acción: Redirigir a URL, ${url}`;
      saveAnswer(data, theme.key);
    }

    setTimeout( () => {window.open(url)}, 2000);
  };

  const redirect_whatsapp = (actionOptions, rule, bot) => {
    let { body, number } = actionOptions;
    const output = bot.store.output();

    for (const property in output) {
      body = body.replace(`{${property}}`, output[property]);
    }

    let url = "";
    if (bot.isMobile) {
      url = "https://api.whatsapp.com/send";
    } else {
      url = "https://web.whatsapp.com/send";
    }

    url += `?phone=${number}&text=${body}`;

    let data = {};
    for (const indexMessage in theme.messages) {
      let message = theme.messages[indexMessage];
      if (message["name"] == rule.name) {
        data = {
          name: message["name"],
          message: message["message"],
          answer: url,
          type: message["type"],
          robot_response_head_id: headId
        };
        break;
      }
    }

    if(Object.keys(data).length) {
      data['action'] = `Acción: Redirigir a Whatsapp, ${url}`;
      saveAnswer(data, theme.key);
    }

    setTimeout( () => {window.open(url)}, 2000);
    
  };

  return <div className="Bot" />;
};

export default Bot;
