import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import ButtonEmbed from './components/ButtonEmbed';
import Chat from "./components/Chat";
import Navbar from './components/Navbar';
import ThemeProvider from './context/ThemeProvider';


function App() {


  return (
    <Router>

      <ThemeProvider>

            <Switch>

              <Route path="/button/:robot">
                <ButtonEmbed />
              </Route>

              <Route path="/:robot" exact>
                <div className="main">
                  <Navbar />
                  <div className="container-chat">
                    <Chat />
                    <div className="status-bar">
                          <span>This bot was built on <a href="https://dimarka.com">diMarka</a></span>
                    </div>
                    </div>
                  </div>
              </Route>

              

            </Switch>

        
      </ThemeProvider>
      
    </Router>
  );
}

export default App;
