import React from 'react'


export const ThemeContext = React.createContext()

const ThemeProvider = (props) => {

    const themes = {
        name : 'Chatbot',
        initial : 'C',
        show_title: false,
        slug : 'robot',
        background: '#101820',
        color: '#fff',
        size: '14px',
        buttonPath: '',
        logoPath: '',
        messages: [],
        recipients: '',
        recipients_cco: '',
        key:'',
        rules:[],
        bot:new Object(),
        showButtonsMinimizeClose: false,
        headId: 0,
        actionClose: 'Chat finalizado',
        timeout: 3600000
    }

    const [theme, setTheme] = React.useState(themes)

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            {props.children}
        </ThemeContext.Provider>
    )
}

export default ThemeProvider
