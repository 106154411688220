import React from "react";
import { useParams, useLocation } from "react-router-dom";
import Bot from "./Bot";
import { ThemeContext } from "../context/ThemeProvider";
import { fetchConnection, fetchSaveAction } from "../services/controller";

function Chat() {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { robot } = useParams();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const getConnection = async (nameRobot) => {
    const key = query.get("key");
    const landing = query.get("landing");
    const uniqid = query.get("uniqid");

    const json = await fetchConnection(key, nameRobot, landing, uniqid);

    const showButtonsMinimizeClose = query.get("showButtonsMinimizeClose");

    setTheme({
      ...theme,
      id: json.data.id,
      name: json.data.name,
      show_title: json.data.show_title,
      initial: json.data.initial,
      slug: nameRobot,
      customer: json.data.customer,
      background_color: json.data.background_color,
      letter_color: json.data.letter_color,
      font_size: json.data.font_size,
      recipients: json.data.recipients,
      recipients_cco: json.data.recipients_cco,
      button_path: json.data.button_path,
      logo_path: json.data.logo_path,
      rules: json.data.rules,
      messages: json.data.messages,
      key: key,
      landing: landing,
      uniqid: uniqid,
      headId: json.data.head_id,
      showButtonsMinimizeClose:
        showButtonsMinimizeClose == null ? true : showButtonsMinimizeClose == "true" ? true : false,
      timeout: json.data.timeout
    });
  };

  React.useEffect(() => {
    getConnection(robot);

    cssInitialize();
  }, []);

  const cssInitialize = () => {
    const cssLetterBot = `
        .yvebot-thread-bot::before {
          content: \'${theme.initial}\';
        }
    `;
    document
      .querySelector("style")
      .insertAdjacentHTML("beforeend", cssLetterBot);
  };

  if (theme.id == undefined) {
    return "Loading....";
  }

  cssInitialize();

  // Aplicar css

  document.documentElement.style.setProperty(
    "--main-background-color",
    theme.background_color
  );

  document.documentElement.style.setProperty(
    "--main-letter-color",
    theme.letter_color
  );

  document.documentElement.style.setProperty(
    "--main-font-size",
    theme.font_size
  );

  return <Bot rules={theme.rules} />;
}

export default Chat;
